import { useAuth0 } from "@auth0/auth0-react";

export function useFetchTwilioToken() {
  const { getAccessTokenSilently } = useAuth0();

  async function getToken(name: string) {
    const accessToken = await getAccessTokenSilently();
    // Use the obtained access token to make authenticated API requests
    const response = await fetch(
      `https://livekit.norwayhouse.ca/generate-token?name=${encodeURIComponent(
        name
      )}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return data.token;
  }

  return {
    getToken,
  };
}
