import React from "react";
import {
  useAudioTrack,
  useDaily,
  useLocalSessionId,
  useVideoTrack,
} from "@daily-co/daily-react";

const CallControls: React.FC = () => {
  const daily = useDaily();
  const localId = useLocalSessionId();
  const camTrack = useVideoTrack(localId);
  const micTrack = useAudioTrack(localId);

  const handleToggleCam = () => {
    if (camTrack && daily) {
      daily.setLocalVideo(camTrack.isOff);
    }
  };

  const handleToggleMic = () => {
    if (micTrack && daily) {
      daily.setLocalAudio(micTrack.isOff);
    }
  };

  const handleLeave = () => {
    if (daily) {
      daily.leave();
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="av">
        <button onClick={handleToggleCam}>
          {camTrack?.isOff ? "Unmute cam" : "Mute cam"}
        </button>
      </div>
      <div className="av">
        <button onClick={handleToggleMic}>
          {micTrack?.isOff ? "Unmute mic" : "Mute mic"}
        </button>
      </div>
      <button onClick={handleLeave}>Leave call</button>
    </div>
  );
};

export default CallControls;
