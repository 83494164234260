import React, { useState, useEffect, useRef } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { SceneController } from "../../../Sockets";
import styled from "@emotion/styled";

type SceneButtonProps = SceneController & {
  onClick: () => void;
  handleToggle: (name: string, id: number) => void;
  enabled: boolean; // New prop for enabling/disabling progress
};

export default function SceneButton(props: SceneButtonProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const filename = props.group + props.name;
  const cleanedFilename = filename.replace(/\s/g, "") + ".mp4";

  const handleMouseEnter = () => {
    if (videoRef.current) {
      // videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      // videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleThumbnailClick = () => {
    props.handleToggle(props.raw, props.id);
    props.onClick();
  };

  return (
    <div>
      <ControllerButton
        id={`${props.id}`}
        onClick={handleThumbnailClick}
        enabled={props.enabled}
      >
        <video
          ref={videoRef}
          src={`/videos/${cleanedFilename}`}
          loop
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disableRemotePlayback
        />
        {/* <HoverVideoPlayer
          videoSrc={`/videos/${cleanedFilename}`}
          restartOnPaused
          muted={true}
          // hoverTarget={() => document.getElementById(`${props.id}`)}
          videoStyle={{
            borderRadius: 16,
          }}
        /> */}
        {props.name}
      </ControllerButton>
    </div>
  );
}

const ControllerButton = styled.button<{ enabled: boolean }>`
  border: none;
  // height: 166px;
  font-size: 12px;
  font-weight: bold;
  width: 202px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 24px;
  color: ${(props) => (props.enabled ? "#3f0" : "white")};
  background: ${(props) =>
    props.enabled ? "#24272c" : "linear-gradient(145deg, #202328, #272a2f)"};
  box-shadow: ${(props) =>
    props.enabled
      ? "inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339"
      : "20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35"};
  transition: all 100ms;
  cursor: pointer;
  &:hover {
    // background: linear-gradient(145deg, #202328, #272a2f);
    // box-shadow: 20px 20px 40px #191b1f, -20px -20px 40px #2f3339;
  }
  video {
    outline: ${(props) => (props.enabled ? "solid 4px #3f0" : "none")};
    outline-offset: -4px;
    border-radius: 16px;
    width: ${(props) => (props.enabled ? "100%" : "100%")};
    box-shadow: ${(props) =>
      props.enabled ? "0px 0px 10px 0px #33ff00ad" : "none"};
  }
`;
const ProgressContainer = styled.div<{ enabled: boolean }>`
  width: 80%;
  height: 1px;
  background-color: none;
  border-radius: 4px;
  margin-top: 8px;
  opacity: ${(props) => (props.enabled ? "1" : "0")};
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => (props.progress >= 100 ? 100 : props.progress)}%;
  height: 100%;
  background-color: #3f0;
`;
