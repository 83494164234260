import React from "react";
import { LocalAudioTrack, LocalVideoTrack, Room } from "twilio-video";
import useLocalTracks from "./hooks/useLocalTracks";
import useRestartAudioTrackOnDeviceChange from "./hooks/useRestartAudioTrackOnDeviceChange";
import { useRoom } from "./hooks/useRoom";

interface Props {
  children: React.ReactNode;
}

interface Values {
  room?: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  getAudioAndVideoTracks: () => void;
  connectToTwilio: (noAudio: boolean, noVideo: boolean) => void;
}

const VideoContext = React.createContext<Values>(undefined!);

export const VideoProvider = (props: Props) => {
  const { children } = props;

  const { getAudioAndVideoTracks, localTracks } = useLocalTracks();

  const { room, connectToTwilio } = useRoom(localTracks);
  useRestartAudioTrackOnDeviceChange(localTracks);

  return (
    <VideoContext.Provider
      value={{
        room: room,
        localTracks,
        getAudioAndVideoTracks,
        connectToTwilio,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideo = () => {
  const context = React.useContext(VideoContext);

  if (context === undefined) {
    throw new Error("useVideo must be used within a VideoProvider");
  }

  return context;
};
