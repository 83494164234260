import { useCallback, useRef, useState } from "react";
import {
  LocalTrack,
  Room,
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import { useFetchTwilioToken } from "../../../../hooks/useFetchTwilioToken";

export function useRoom(localTracks: LocalTrack[] | undefined) {
  const { getToken } = useFetchTwilioToken();
  const [room, setRoom] = useState<Room | null>(null);
  const isConnecting = useRef<boolean>(false);

  const connectToTwilio = useCallback(
    (noAudio: boolean, noVideo: boolean) => {
      async function createRoom() {
        const token = await getToken(Date.now().toString());

        async function getTracks() {
          if (!localTracks) {
            const devices = [];
            if (!noAudio) {
              devices.push(await createLocalAudioTrack());
            }
            if (!noVideo) {
              devices.push(await createLocalVideoTrack());
            }
            return devices;
          } else {
            localTracks.filter(
              (lt) =>
                (noAudio && lt.kind !== "audio") ||
                (noVideo && lt.kind !== "video")
            );
          }
        }

        const room = await connect(token, {
          tracks: await getTracks(),
          name: "control-surface-room",
        });

        setRoom(room);

        function disconnect() {
          room.disconnect();
        }

        room.once("disconnected", () => {
          setTimeout(() => setRoom(null));
          window.removeEventListener("beforeunload", disconnect);
        });

        window.addEventListener("beforeunload", disconnect);
      }

      if (!room && !isConnecting.current) {
        isConnecting.current = true;
        createRoom().catch((err) => {
          console.log(`There was an issue creating a room: ${err}`);
        });
      }
    },
    [room, getToken, localTracks]
  );

  return {
    room,
    isConnecting,
    connectToTwilio,
  };
}
