import { useDaily, useDailyEvent } from "@daily-co/daily-react";
import styled from "@emotion/styled";
import React from "react";

interface PreJoinProps {
  onJoin: () => void;
}

const PreJoin: React.FC<PreJoinProps> = ({ onJoin }) => {
  const daily = useDaily();

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (daily) {
      daily.join();
    }
  };

  // Set up an event listener for when the local participant has joined the meeting
  useDailyEvent("joined-meeting", () => {
    // Assuming you want to set send settings immediately after joining
    if (daily) {
      daily.updateSendSettings({
        // video: "bandwidth-optimized",
        //video: 'bandwidth-and-quality-balanced', // can also use 'default-video'
        // video: "quality-optimized",
        video: {
          maxQuality: "low",
          encodings: {
            low: {
              maxBitrate: 900000,
              scaleResolutionDownBy: 2,
              maxFramerate: 30,
            },
          },
        },
        // Add audio settings if needed
      });
      onJoin(); // Call the onJoin callback
    }
  });

  return (
    <Form className="JoinForm" onSubmit={handleSubmit}>
      <ConnectButton type="submit">CONNECT</ConnectButton>
    </Form>
  );
};

const ConnectButton = styled.button<{}>`
  border: none;
  color: #ef5da8;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  background: linear-gradient(145deg, #202328, #272a2f);
  box-shadow: 20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35;
  cursor: pointer;
`;
const Form = styled.form`
  padding: 0;
`;

export default PreJoin;
