import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { SceneItemController } from "../../../Sockets";

type SceneChildButtonProps = SceneItemController & {
  onClick: () => void;
  handleToggle: (id: number, duration: number) => void;
  enabled: boolean; // New prop for enabling/disabling progress
};

export default function SceneChildButton(props: SceneChildButtonProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState<number>(0);
  const [progressInterval, setProgressInterval] =
    useState<NodeJS.Timeout | null>(null);
  let filename = "";
  if (props.duration > 0) {
    filename = props.group + props.name + props.wardrobe + props.duration;
  } else {
    filename = props.group + props.name + props.wardrobe;
  }
  const cleanedFilename = filename.replace(/\s/g, "") + ".mp4";

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((err) => {
        console.log(err);
      });
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  // Function to handle progress calculation
  const calculateProgress = (duration: number) => {
    const startTime = Date.now(); // Record the start time

    const interval = setInterval(() => {
      const currentTime = Date.now(); // Get the current time
      const elapsedTime = currentTime - startTime; // Calculate elapsed time
      if (elapsedTime < duration) {
        const newProgress = Math.round((elapsedTime / duration) * 100); // Round progress to whole number
        setProgress(newProgress);
      } else {
        clearInterval(interval); // Stop the timer when progress reaches 100%
        setProgress(0); // Reset progress to 0
      }
    }, 10); // Adjust the interval for a smoother animation

    setProgressInterval(interval);
  };

  useEffect(() => {
    // Clear the interval when the component unmounts or when it's disabled
    return () => {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    };
  }, [progressInterval]);

  useEffect(() => {
    // Listen for changes in isEnabled and start calculating progress if it's enabled
    if (props.enabled) {
      calculateProgress(props.duration || 1000);
    }
  }, [props.enabled, props.duration]);

  const handleClick = () => {
    props.handleToggle(props.id, props.duration || 0);
    props.onClick();
  };

  return (
    <div>
      <ControllerButton
        id={`${props.id}`}
        onClick={handleClick}
        enabled={props.enabled}
      >
        <video
          ref={videoRef}
          src={`/videos/${cleanedFilename}`}
          loop
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disableRemotePlayback
        />
        {props.duration > 0 ? (
          <ProgressContainer enabled={props.enabled}>
            <ProgressBar progress={progress} />
          </ProgressContainer>
        ) : null}
        {props.name}
      </ControllerButton>
    </div>
  );
}

const ControllerButton = styled.button<{ enabled: boolean }>`
  border: none;
  // height: 166px;
  font-size: 12px;
  font-weight: bold;
  width: 202px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 24px;
  color: ${(props) => (props.enabled ? "#3f0" : "white")};
  background: ${(props) =>
    props.enabled ? "#24272c" : "linear-gradient(145deg, #202328, #272a2f)"};
  box-shadow: ${(props) =>
    props.enabled
      ? "inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339"
      : "20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35"};
  transition: all 300ms;
  cursor: pointer;
  &:hover {
    // background: linear-gradient(145deg, #202328, #272a2f);
    // box-shadow: 20px 20px 40px #191b1f, -20px -20px 40px #2f3339;
  }
  video {
    outline: ${(props) => (props.enabled ? "solid 4px #3f0" : "none")};
    outline-offset: -4px;
    border-radius: 16px;
    width: ${(props) => (props.enabled ? "100%" : "100%")};
    box-shadow: ${(props) =>
      props.enabled ? "0px 0px 10px 0px #33ff00ad" : "none"};
  }
`;
const ProgressContainer = styled.div<{ enabled: boolean }>`
  width: 80%;
  height: 1px;
  background-color: none;
  border-radius: 4px;
  margin-top: 8px;
  opacity: ${(props) => (props.enabled ? "1" : "0")};
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => (props.progress >= 100 ? 100 : props.progress)}%;
  height: 100%;
  background-color: #3f0;
`;
