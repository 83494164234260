import OBSWebSocket, {
  OBSEventTypes,
  OBSRequestTypes,
  OBSResponseTypes,
} from "obs-websocket-js";
import { io } from "socket.io-client";

export const livetrackSocket = io("wss://livekit.norwayhouse.ca");
const obs = new OBSWebSocket();
const unreal = new OBSWebSocket();

const MasterScene = "Main|Main";
const obscomputerurl = "wsobs.norwayhouse.ca";
// const unrealcomputerurl = "wsunreal.norwayhouse.ca";
const unrealcomputerurl = obscomputerurl;

export interface SceneItemController {
  group: string;
  name: string;
  wardrobe?: string;
  duration: number;
  enabled: boolean;
  id: number;
  raw: string;
}

export interface SceneController {
  group: string;
  name: string;
  isProgram: boolean;
  id: number;
  raw: string;
}

type SceneItem = {
  inputKind: null | string;
  isGroup: boolean;
  sceneItemBlendMode: string;
  sceneItemEnabled: boolean;
  sceneItemId: number;
  sceneItemIndex: number;
  sceneItemLocked: boolean;
  sceneItemTransform: {
    alignment: number;
    boundsAlignment: number;
    boundsHeight: number;
    boundsType: string;
    boundsWidth: number;
    cropBottom: number;
    cropLeft: number;
    cropRight: number;
    cropTop: number;
    height: number;
    positionX: number;
    positionY: number;
    rotation: number;
    scaleX: number;
    scaleY: number;
    sourceHeight: number;
    sourceWidth: number;
    width: number;
  };
  sourceName: string;
  sourceType: string;
};

type Scene = {
  sceneIndex: number;
  sceneName: string;
};

///////////////////////////////////////////////////////////////////////////////////
// OBSCOMPUTER FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////
export async function ObsSystemConnect() {
  try {
    const { obsWebSocketVersion, negotiatedRpcVersion } = await obs.connect(
      `wss://${obscomputerurl}`,
      "moonboot",
      {
        rpcVersion: 1,
      }
    );
    console.log(
      `Connected to wsobs server ${obsWebSocketVersion} (using RPC ${negotiatedRpcVersion})`
    );
  } catch (error: any) {
    console.error("Failed to connect", error.code, error.message);
  }
}

//Get all Children in a Scene
export async function GetSceneChildren(scene: string) {
  try {
    const response = await obs.call("GetSceneItemList", { sceneName: scene });
    const validatedSceneItems: SceneItemController[] = response.sceneItems
      .filter((sceneItem) => {
        // Filter out items that do not contain a "|"
        const sourceName = sceneItem.sourceName as string;
        return (
          sourceName === undefined || // Include items without sourceName
          (sourceName && sourceName.includes("|"))
        );
      })
      .map((sceneItem) => {
        // Validate the structure of the sceneItem using the SceneItem type
        const validatedSceneItem: SceneItem = sceneItem as SceneItem;
        const sceneNameParts = (validatedSceneItem.sourceName || "").split("|");
        // Convert duration to a floating-point number
        const duration = parseFloat(sceneNameParts[3] || "0");

        return {
          group: sceneNameParts[0] || "",
          name: sceneNameParts[1] || "",
          wardrobe: sceneNameParts[2] || "",
          duration: duration,
          id: validatedSceneItem.sceneItemId,
          enabled: validatedSceneItem.sceneItemEnabled as boolean,
          raw: validatedSceneItem.sourceName,
        };
      });

    return validatedSceneItems;
  } catch (err) {
    console.error("Failed to get scene items:", err);
  }
}

//Set Scene
export async function SetScene(scene: string) {
  obs
    .call("SetCurrentProgramScene", { sceneName: scene })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error("Failed to get scene items:", err);
    });
}

export async function GetScenes() {
  try {
    const response = await obs.call("GetSceneList");
    const activeScene = await obs.call("GetCurrentProgramScene");
    const validatedScenes: SceneController[] = response.scenes
      .filter((scene) => {
        // Filter out items that do not contain a "|"
        const sourceName = scene.sceneName as string;
        return (
          sourceName === undefined || // Include items without sourceName
          (sourceName && sourceName.includes("|"))
        );
      })
      .map((scene) => {
        // Validate the structure of the sceneItem using the SceneItem type
        const validatedScenes: Scene = scene as Scene;
        const sceneNameParts = (validatedScenes.sceneName || "").split("|");
        const isProgram =
          activeScene.currentProgramSceneName === validatedScenes.sceneName;

        return {
          group: sceneNameParts[0] || "",
          name: sceneNameParts[1] || "",
          isProgram: isProgram,
          id: validatedScenes.sceneIndex,
          raw: validatedScenes.sceneName,
        };
      });

    return validatedScenes;
  } catch (err) {
    console.error("Failed to get scene items:", err);
  }
}

export async function SetSceneChildOff(name: string, id: number) {
  const sceneName = name;
  const sceneItemId = id;

  try {
    await obs.call("SetSceneItemEnabled", {
      sceneName: sceneName,
      sceneItemId: sceneItemId,
      sceneItemEnabled: false,
    });
    console.log("Scene item enabled: false");
  } catch (err) {
    console.error("Error:", err);
  }
}

export async function SetSceneChildOn(name: string, id: number) {
  const sceneName = name;
  const sceneItemId = id;

  try {
    await obs.call("SetSceneItemEnabled", {
      sceneName: sceneName,
      sceneItemId: sceneItemId,
      sceneItemEnabled: true,
    });
    console.log("Scene item enabled: true");
  } catch (err) {
    console.error("Error:", err);
  }
}

// Reset all enabled scene children to disabled
export async function ResetSceneChildren() {
  const sceneChildrenResponse = await GetSceneChildren(MasterScene);

  if (sceneChildrenResponse) {
    // Filter and get the IDs of all enabled scene children
    const enabledSceneChildIds = sceneChildrenResponse
      .filter((item) => item.enabled)
      .map((item) => item.id);

    // Turn off all enabled scene children
    for (const id of enabledSceneChildIds) {
      await SetSceneChildOff(MasterScene, id);
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////
// THESE WILL BE ONLY FOR THE VIDEOWALL - REFACTOR THIS LATER BE LESS REPEATITIVE//
///////////////////////////////////////////////////////////////////////////////////

export async function UnrealSystemConnect() {
  try {
    const { obsWebSocketVersion, negotiatedRpcVersion } = await unreal.connect(
      `wss://${unrealcomputerurl}`,
      "moonboot",
      {
        rpcVersion: 1,
      }
    );
    console.log(
      `Connected to wsunreal server ${obsWebSocketVersion} (using RPC ${negotiatedRpcVersion})`
    );
  } catch (error: any) {
    console.error("Failed to connect", error.code, error.message);
  }
}

export async function GetVideowallScenes() {
  try {
    const response = await unreal.call("GetSceneList");
    const activeScene = await unreal.call("GetCurrentProgramScene");
    const validatedScenes: SceneController[] = response.scenes
      .filter((scene) => {
        // Filter out items that do not contain a "|"
        const sourceName = scene.sceneName as string;
        return (
          sourceName === undefined || // Include items without sourceName
          (sourceName && sourceName.includes("|"))
        );
      })
      .map((scene) => {
        // Validate the structure of the sceneItem using the SceneItem type
        const validatedScenes: Scene = scene as Scene;
        const sceneNameParts = (validatedScenes.sceneName || "").split("|");
        const isProgram =
          activeScene.currentProgramSceneName === validatedScenes.sceneName;

        return {
          group: sceneNameParts[0] || "",
          name: sceneNameParts[1] || "",
          isProgram: isProgram,
          id: validatedScenes.sceneIndex,
          raw: validatedScenes.sceneName,
        };
      });

    return validatedScenes;
  } catch (err) {
    console.error("Failed to get scene items:", err);
  }
}

//Set Scene
export async function SetVideowallScene(scene: string) {
  unreal
    .call("SetCurrentProgramScene", { sceneName: scene })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error("Failed to get scene items:", err);
    });
}

///////////////////////////////////////////////////////////////////////////////////
// CONNECT
///////////////////////////////////////////////////////////////////////////////////
await ObsSystemConnect();
await UnrealSystemConnect();
