import React from "react";
import { useDailyEvent, useParticipantIds } from "@daily-co/daily-react";
import CallControls from "./CallControls";
import Tile from "./Tile";
import { useParticipants } from "../video/hooks/useParticipants";

// Define the props expected by the Call component
interface CallProps {
  onLeave: () => void;
}

const Call: React.FC<CallProps> = ({ onLeave }) => {
  const participantIds = useParticipantIds();
  useDailyEvent("left-meeting", onLeave);

  return (
    <div className="Call">
      <div className="tiles">
        {participantIds.map((id) => (
          <Tile key={id} sessionId={id} />
        ))}
      </div>
      <CallControls />
    </div>
  );
};

export default Call;
