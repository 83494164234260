import styled from "@emotion/styled";
import { DailyAudio, DailyProvider } from "@daily-co/daily-react";
import { useState } from "react";
import PreJoin from "./PreJoin";
import Call from "./Call";
import ConfiguredDailyProvider from "./ConfiguredDailyProvider";
import CallControls from "./CallControls";

export function DailyVideoChatComponent() {
  const [joined, setJoined] = useState(false);
  const roomUrl = "https://herrishow.daily.co/Producer-Pro";
  const role = "viewer";

  return (
    <ConfiguredDailyProvider role={role} roomUrl={roomUrl}>
      <DailyAudio />
      <Container>
        <ParticipantContainer>
          {joined ? (
            <Call onLeave={() => setJoined(false)} />
          ) : (
            <PreJoin onJoin={() => setJoined(true)} />
          )}
        </ParticipantContainer>
      </Container>
    </ConfiguredDailyProvider>
  );
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
});

const ParticipantsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

const ParticipantContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
});

const ConnectButton = styled.button<{}>`
  border: none;
  color: #ef5da8;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  background: linear-gradient(145deg, #202328, #272a2f);
  box-shadow: 20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35;
  cursor: pointer;
`;

const MenuButton = styled.button<{ enabled?: boolean }>`
  border: none;
  color: ${(props) => (props.enabled ? "#33FF00" : "#ef5da8")};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  // height: 66px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) =>
    props.enabled ? "#24272c" : "linear-gradient(145deg, #202328, #272a2f)"};
  box-shadow: ${(props) =>
    props.enabled
      ? "inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339"
      : "20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35"};
  cursor: pointer;
  &:active {
    transform: translateY(4px);
`;

export default DailyVideoChatComponent;
