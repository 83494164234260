import React, { useState, useEffect, ReactNode } from "react";
import { DailyProvider } from "@daily-co/daily-react";
import axios from "axios";

interface ConfiguredDailyProviderProps {
  role: "broadcaster" | "viewer";
  roomUrl: string;
  children: ReactNode; // Add this line to include the children prop
}

const ConfiguredDailyProvider: React.FC<ConfiguredDailyProviderProps> = ({
  role,
  roomUrl,
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const apiUrl = `https://livekit.norwayhouse.ca/generate-daily-token`;
        const requestBody = {
          room: "Producer-Pro", // This should match with your backend expectation
          isOwner: role === "broadcaster", // Example logic, adjust as necessary
          permissions: {
            // Assuming permissions are needed. Adjust according to your API.
            hasPresence: role === "broadcaster",
            canSend: role === "broadcaster",
            canAdmin: role === "broadcaster",
          },
        };
        const response = await axios.post<{ token: string }>(
          apiUrl,
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              // Include Authorization header if your endpoint requires authentication
            },
          }
        );
        setToken(response.data.token);
      } catch (error) {
        console.error("Error fetching token:", error);
        setToken(null);
      }
    };

    fetchToken();
  }, [role]);

  if (!token) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <DailyProvider
      url={roomUrl}
      token={token}
      userName={role}
      dailyConfig={{ useDevicePreferenceCookies: true }}
    >
      {children}
    </DailyProvider>
  );
};

export default ConfiguredDailyProvider;
