import React from "react";
import {
  DailyVideo,
  useActiveSpeakerId,
  useAudioTrack,
  useParticipantProperty,
} from "@daily-co/daily-react";

interface TileProps {
  sessionId: string;
}

const Tile: React.FC<TileProps> = ({ sessionId }) => {
  const activeId = useActiveSpeakerId();
  const username = useParticipantProperty(sessionId, "user_name") as string;
  const audioTrack = useAudioTrack(sessionId);

  return username === "viewer" ? null : (
    <div className="Tile">
      <DailyVideo
        key={sessionId}
        automirror
        sessionId={sessionId}
        type={"video"}
        style={{ width: "100%", borderRadius: "24px" }}
      />
      <div className="Username">
        <span role="img" aria-label={audioTrack?.isOff ? "Muted" : "Unmuted"}>
          {audioTrack?.isOff ? "🔇" : "🔈"}
        </span>
        <span>{username || "Guest"}</span>
      </div>
    </div>
  );
};

export default Tile;
