import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DailyAudio, DailyProvider } from "@daily-co/daily-react";
import PreJoin from "../../../components/dailyHacking/PreJoin";
import Call from "../../../components/dailyHacking/Call";
import ConfiguredDailyProvider from "../../../components/dailyHacking/ConfiguredDailyProvider";
import { useDevices } from "@daily-co/daily-react";
import DeviceSelector from "./DeviceSelector";

export function LocalStream() {
  const [joined, setJoined] = useState(false);
  const roomUrl = "https://herrishow.daily.co/Producer-Pro";
  const role = "broadcaster";
  // const devices = useDevices();

  return (
    <Container>
      <ConfiguredDailyProvider role={role} roomUrl={roomUrl}>
        <DailyAudio />
        <Container>
          <ParticipantContainer>
            {joined ? (
              <Call onLeave={() => setJoined(false)} />
            ) : (
              <div>
                <PreJoin onJoin={() => setJoined(true)} />
              </div>
            )}
          </ParticipantContainer>
          <DeviceSelector />
        </Container>
      </ConfiguredDailyProvider>
    </Container>
  );
}

const ParticipantContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
