import { Outlet } from "react-router-dom";
import { ParticipantProvider } from "./video/ParticipantsProvider";
import { VideoProvider } from "./video/VideoProvider";

export function Layout() {
  return (
    <VideoProvider>
      <ParticipantProvider>
        <Outlet />
      </ParticipantProvider>
    </VideoProvider>
  );
}
