import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./ui/components/Layout";
import { ProtectedRoute } from "./ui/components/ProtectedRoute";
import ControlSurface from "./ui/pages/secure/control-surface/ControlSurface";
import { LocalStream } from "./ui/pages/secure/local-stream/LocalStream";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute component={Layout} />}>
          <Route path="/broadcast" element={<LocalStream />} />
          <Route path="/control-surface" element={<ControlSurface />} />
          <Route
            path="*"
            element={<Navigate to="/control-surface" replace />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
