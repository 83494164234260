export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {};

export const SELECTED_VIDEO_INPUT_KEY = "VIDEO-ID";
export const SELECTED_AUDIO_INPUT_KEY = "AUDIO-ID";

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
    videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
    audioOutputDevices: devices.filter(
      (device) => device.kind === "audiooutput"
    ),
    hasAudioInputDevices: devices.some(
      (device) => device.kind === "audioinput"
    ),
    hasVideoInputDevices: devices.some(
      (device) => device.kind === "videoinput"
    ),
  };
}

export async function isPermissionDenied(name: "camera" | "microphone") {
  const permissionName = name as PermissionName;

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({
        name: permissionName,
      });
      return result.state === "denied";
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
