import React, { createContext } from "react";
import { RemoteParticipant } from "twilio-video";
import useParticipantsView from "./hooks/useGalleryViewParticipants";

export interface IParticipantContext {
  participantsView: RemoteParticipant[];
}

export const ParticipantContext = createContext<IParticipantContext>(null!);

export const ParticipantProvider = (props: { children: React.ReactNode }) => {
  const participantsView = useParticipantsView();

  return (
    <ParticipantContext.Provider
      value={{
        participantsView,
      }}
    >
      {props.children}
    </ParticipantContext.Provider>
  );
};

export const useParticipantView = () => {
  const context = React.useContext(ParticipantContext);

  if (context === undefined) {
    throw new Error(
      "useParticipantView must be used within a ParticipantProvider"
    );
  }

  return context;
};
