import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType } from "react";

export const ProtectedRoute = (props: { component: ComponentType }) => {
  const { component } = props;

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div>Loading...</div>,
  });

  return <Component />;
};
