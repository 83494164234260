import React, { useState, useEffect } from "react";
import { useDaily } from "@daily-co/daily-react";
import styled from "@emotion/styled";

// Define a type for the devices state
interface DevicesState {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
}

const DeviceSelector: React.FC = () => {
  const daily = useDaily();
  // Initialize the state with explicit typing
  const [devices, setDevices] = useState<DevicesState>({
    audio: [],
    video: [],
  });

  useEffect(() => {
    const fetchDevices = async () => {
      if (daily) {
        try {
          const deviceInfos = await daily.enumerateDevices();
          const audioInputDevices = deviceInfos.devices.filter(
            (device) => device.kind === "audioinput"
          );
          const videoInputDevices = deviceInfos.devices.filter(
            (device) => device.kind === "videoinput"
          );
          // Now TypeScript knows the structure of the devices state
          setDevices({
            audio: audioInputDevices,
            video: videoInputDevices,
          });
        } catch (error) {
          console.error("Error fetching devices:", error);
        }
      }
    };

    fetchDevices();
  }, [daily]);

  const handleAudioDeviceChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const deviceId = e.target.value;
    if (daily) {
      await daily.setInputDevicesAsync({ audioDeviceId: deviceId });
    }
  };

  const handleVideoDeviceChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const deviceId = e.target.value;
    if (daily) {
      await daily.setInputDevicesAsync({ videoDeviceId: deviceId });
    }
  };

  return (
    <DeviceSelectDiv>
      <OptionDiv>
        <label>Audio</label>
        <MenuSelect onChange={handleAudioDeviceChange}>
          {devices.audio.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || "Unnamed Audio Device"}
            </option>
          ))}
        </MenuSelect>
      </OptionDiv>
      <OptionDiv>
        <label>Video</label>
        <MenuSelect onChange={handleVideoDeviceChange}>
          {devices.video.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || "Unnamed Video Device"}
            </option>
          ))}
        </MenuSelect>
      </OptionDiv>
    </DeviceSelectDiv>
  );
};

const MenuSelect = styled.select`
  font-weight: bold;
  width: 100%;
  padding: 16px;
  text-transform: uppercase;
  cursor: pointer;
`;

const DeviceSelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export default DeviceSelector;
