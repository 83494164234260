import styled from "@emotion/styled";
import { ChangeEvent, useEffect, useState } from "react";
import {
  GetSceneChildren,
  GetScenes,
  GetVideowallScenes,
  ResetSceneChildren,
  SceneController,
  SceneItemController,
  SetScene,
  SetSceneChildOff,
  SetSceneChildOn,
  SetVideowallScene,
} from "../../../../Sockets";
import SceneButton from "../../../components/sceneButton/SceneButton";
import SceneChildButton from "../../../components/sceneChildButton/SceneChildButton";
import waveImage from "../../../../assets/waveform.png";
import { livetrackSocket } from "../../../../Sockets";
import HerriSoftLogoSVG from "../../../../assets/herrisoft.svg";
import Modal from "@mui/material/Modal";
import { useLocalStorage } from "../../../../hooks/useLocalState";
import DailyVideoChatComponent from "../../../components/dailyHacking/DailyVideoComponent";

const MasterScene = "Main|Main";

function ControlSurface() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [sceneChildren, setSceneChildren] = useState<SceneItemController[]>([]);
  const [crowdSfx, setCrowdSfx] = useState<boolean>(false);
  const [themeSfx, setThemeSfx] = useState<boolean>(false);
  const [scenes, setScenes] = useState<SceneController[]>([]);
  const [videowallScenes, setVideowallScenes] = useState<SceneController[]>([]);
  const [activeVideowallSceneId, setActiveVideowallSceneId] =
    useState<number>(999);
  const [activeMasterScenechildId, setActiveMasterScenechildId] =
    useState<number>(999);
  const [activeSceneId, setActiveSceneId] = useState<number>(999);
  const [activeSceneChildId, setActiveSceneChildId] = useState<number>(0);
  const [selectedWardrobe, setSelectedWardrobe] = useLocalStorage(
    "Wardrobe",
    "pink"
  );
  const wardrobeOptions = ["pink", "blue", "yellow"];

  const cleanupState = async (clearScenes: boolean) => {
    if (clearScenes) {
      await SetSceneChildOff(MasterScene, activeMasterScenechildId);
    }

    // Update sceneChildren to reflect the change
    setSceneChildren((prevSceneChildren) => {
      const updatedSceneChildren = prevSceneChildren.map((item) => {
        if (item.id === activeSceneChildId) {
          return { ...item, enabled: false };
        }
        return item;
      });
      return updatedSceneChildren;
    });

    // Update Scenes to reflect the change
    setScenes((prevScenes) => {
      const updatedScenes = prevScenes.map((item) => {
        if (item.id === activeMasterScenechildId) {
          return { ...item, isProgram: false };
        }
        return item;
      });
      return updatedScenes;
    });

    // Update VideoWallScenes to reflect the change
    setVideowallScenes((prevVideowallScenes) => {
      const updatedVideowallScenes = prevVideowallScenes.map((item) => {
        if (item.id === activeVideowallSceneId) {
          return { ...item, isProgram: false };
        }
        return item;
      });
      return updatedVideowallScenes;
    });
  };

  const handleColorChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedWardrobe(event.target.value);
    await ResetSceneChildren();
  };

  const handleCrowd = () => {
    const prevCrowdSfx = crowdSfx;
    setCrowdSfx(!prevCrowdSfx);
    if (prevCrowdSfx) {
      // If the previous value was true, emit "stopCrowdCommand"
      livetrackSocket.emit("stopCrowdCommand", "Stop Crowd FX");
    } else {
      // If the previous value was false, emit "playCrowdCommand"
      livetrackSocket.emit("playCrowdCommand", "Play Crowd FX");
    }
  };

  const handleTheme = () => {
    const prevThemeSfx = themeSfx;
    setThemeSfx(!prevThemeSfx);
    if (prevThemeSfx) {
      // If the previous value was true, emit "stopCrowdCommand"
      livetrackSocket.emit("stopThemeCommand", "Stop Theme FX");
    } else {
      // If the previous value was false, emit "playCrowdCommand"
      livetrackSocket.emit("playThemeCommand", "Play Theme FX");
    }
  };

  const handleMenuClick = () => {
    const prevMenuOpen = menuOpen;
    setMenuOpen(!prevMenuOpen);
  };

  const handleSceneChildState = (id: number) => {
    const itemIndex = sceneChildren.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedSceneChildren = [...sceneChildren];
      updatedSceneChildren[itemIndex].enabled = true;
      setSceneChildren(updatedSceneChildren);
    }
  };

  const handleSceneState = (id: number) => {
    const itemIndex = scenes.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedScenes = [...scenes];
      updatedScenes[itemIndex].isProgram = true;
      setScenes(updatedScenes);
    }
  };

  const handleVideowallSceneState = (id: number) => {
    const itemIndex = videowallScenes.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedScenes = [...videowallScenes];
      updatedScenes[itemIndex].isProgram = true;
      setVideowallScenes(updatedScenes);
    }
  };
  const HandleResetUI = async () => {
    await SetScene(MasterScene);
    await ResetSceneChildren();
    await FetchData;
    window.location.reload();
  };

  const HandleSceneChildToggle = async (id: number, duration: number) => {
    const enabledSceneChildren = sceneChildren.filter(
      (item) => item.enabled && item.group.toLowerCase() !== "master"
    );
    if (enabledSceneChildren.length > 0) {
      for (const enabledChild of enabledSceneChildren) {
        await SetSceneChildOff(MasterScene, enabledChild.id);
      }
    }
    if (
      sceneChildren.find((item) => item.id === id)?.group.toLowerCase() ===
      "master"
    ) {
      setActiveMasterScenechildId(id);
    }
    await SetScene(MasterScene);
    await SetSceneChildOn(MasterScene, id);
    if (duration !== 0) {
      setTimeout(async () => {
        await SetSceneChildOff(MasterScene, id);
        setSceneChildren((prevSceneChildren) => {
          const updatedSceneChildren = prevSceneChildren.map((item) => {
            if (item.id === id) {
              return { ...item, enabled: false };
            }
            return item;
          });
          return updatedSceneChildren;
        });
      }, duration);
    } else {
      setSceneChildren((prevSceneChildren) => {
        const updatedSceneChildren = prevSceneChildren.map((item) => {
          if (item.id === id) {
            return { ...item, enabled: true };
          }
          return item;
        });
        return updatedSceneChildren;
      });
    }
  };

  const HandleVideowallSceneToggle = async (name: string, id: number) => {
    await SetVideowallScene(name);
    setActiveVideowallSceneId(id);
  };

  const HandleSceneToggle = async (name: string, id: number) => {
    await SetScene(name);
    setActiveSceneId(id);
  };

  const FetchData = async () => {
    try {
      const sceneResponse = await GetScenes();
      const sceneChildrenResponse = await GetSceneChildren(MasterScene);
      const videowallScenesResponse = await GetVideowallScenes();
      const reversedScenes = sceneResponse?.reverse() || [];
      const reversedSceneChildren = sceneChildrenResponse?.reverse() || [];
      const reversedVideowallScenes = videowallScenesResponse?.reverse() || [];
      setScenes(reversedScenes);
      setSceneChildren(reversedSceneChildren);
      setVideowallScenes(reversedVideowallScenes);
    } catch (error) {
      console.error("Error fetching obs payload:", error);
    }
  };

  useEffect(() => {
    FetchData();
  });

  return (
    <Wrapper>
      <AppBar>
        <HerriSoftLogo
          src={HerriSoftLogoSVG}
          onClick={() => handleMenuClick()}
        />
      </AppBar>
      <ModalExterior
        open={menuOpen}
        onClose={handleMenuClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalInterior>
          <h1>Menu</h1>
          <MenuSelectContainer>
            <h4>Outfit Color</h4>
            <MenuSelect
              id="colorSelect"
              onChange={handleColorChange}
              value={selectedWardrobe}
            >
              {wardrobeOptions.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </MenuSelect>
          </MenuSelectContainer>
          <MenuButton onClick={() => HandleResetUI()}>RUN SYNC</MenuButton>
        </ModalInterior>
      </ModalExterior>
      <Content>
        <ControllerButtonHolder enabled>
          <Title>{`Master (${selectedWardrobe})`}</Title>
          {sceneChildren.map((item) =>
            item.group.toLowerCase() === "master" &&
            item.wardrobe?.toLowerCase() === selectedWardrobe ? (
              <SceneChildButton
                key={item.id}
                {...item}
                onClick={() => {
                  handleSceneChildState(item.id);
                  cleanupState(true);
                }}
                handleToggle={HandleSceneChildToggle}
              />
            ) : null
          )}
          {sceneChildren.map((item) =>
            item.group.toLowerCase() === "master" &&
            item.name.toLowerCase().includes("cohost") ? (
              <SceneChildButton
                key={item.id}
                {...item}
                onClick={() => {
                  handleSceneChildState(item.id);
                  cleanupState(true);
                }}
                handleToggle={HandleSceneChildToggle}
              />
            ) : null
          )}
        </ControllerButtonHolder>
        <CenterControls>
          <ControllerButtonHolder enabled isRow>
            <InnerCol>
              <CrowdHeader>
                <Title>Crowd</Title>
                <CrowdSfxContainer>
                  <SmallButton enabled={themeSfx} onClick={handleTheme}>
                    <CrowdInnerRow>
                      THEME FX
                      <WaveWrapper enabled={themeSfx}>
                        <img src={waveImage} />
                      </WaveWrapper>
                    </CrowdInnerRow>
                  </SmallButton>
                  <SmallButton enabled={crowdSfx} onClick={handleCrowd}>
                    <CrowdInnerRow>
                      CROWD FX
                      <WaveWrapper enabled={crowdSfx}>
                        <img src={waveImage} />
                      </WaveWrapper>
                    </CrowdInnerRow>
                  </SmallButton>
                </CrowdSfxContainer>
              </CrowdHeader>
              <InnerRow>
                {sceneChildren.map((item) =>
                  item.group.toLowerCase() === "crowd" ? (
                    <SceneChildButton
                      key={item.id}
                      {...item}
                      onClick={() => {
                        handleSceneChildState(item.id);
                        cleanupState(false);
                      }}
                      handleToggle={HandleSceneChildToggle}
                    />
                  ) : null
                )}
              </InnerRow>
            </InnerCol>
          </ControllerButtonHolder>

          <ControllerButtonHolder enabled isRow>
            <InnerCol>
              <Title>{`Wide (${selectedWardrobe})`}</Title>
              <InnerRow>
                {sceneChildren.map((item) =>
                  item.group.toLowerCase() === "wide" &&
                  item.wardrobe?.toLowerCase() === selectedWardrobe ? (
                    <SceneChildButton
                      key={item.id}
                      {...item}
                      onClick={() => {
                        handleSceneChildState(item.id);
                        cleanupState(false);
                      }}
                      handleToggle={HandleSceneChildToggle}
                    />
                  ) : null
                )}
              </InnerRow>
            </InnerCol>
          </ControllerButtonHolder>
          <ControllerButtonHolder enabled isRow>
            <InnerCol>
              <Title>Commercials</Title>
              <InnerRow>
                {scenes.map((item) =>
                  item.group.toLowerCase() === "commercial" ? (
                    <SceneButton
                      key={item.id}
                      {...item}
                      onClick={() => {
                        handleSceneState(item.id);
                        cleanupState(true);
                      }}
                      handleToggle={HandleSceneToggle}
                      enabled={item.isProgram}
                    />
                  ) : null
                )}
              </InnerRow>
            </InnerCol>
          </ControllerButtonHolder>
        </CenterControls>
        <ControllerButtonHolder enabled>
          <Title>Videowall</Title>
          {videowallScenes.map((item) =>
            item.group.toLowerCase() === "videowall" ? (
              <SceneButton
                key={item.id}
                {...item}
                onClick={() => {
                  handleVideowallSceneState(item.id);
                  cleanupState(false);
                }}
                handleToggle={HandleVideowallSceneToggle}
                enabled={item.isProgram}
              />
            ) : null
          )}
        </ControllerButtonHolder>
        <ExternalMonitorContainer enabled>
          <Title>External Monitor</Title>
          <DailyVideoChatComponent />
        </ExternalMonitorContainer>
      </Content>
    </Wrapper>
  );
}

export default ControlSurface;

const AppBar = styled.div<{}>`
  display: flex;
  flex-direction: row;
  background: #24272c;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339;
  padding: 0px 24px;
  height: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background: #24272c;
  gap: 16px;
`;

const ControllerButtonHolder = styled.div<{
  enabled: boolean;
  isRow?: boolean;
}>`
  display: flex;
  gap: 16px;
  flex-direction: ${(props) => (props.isRow ? "row" : "column")};
  align-items: center;
  color: white;
  padding: 16px;
  background: #272a2f;
  box-shadow: 0px 20px 40px #1d1f23, -20px -20px 40px #2b2f35;
  border-radius: 24px;
  color: ${(props) => (props.enabled ? "white" : "white")};
`;

const ExternalMonitorContainer = styled.div<{
  enabled: boolean;
  isRow?: boolean;
}>`
  display: flex;
  gap: 24px;
  flex-direction: ${(props) => (props.isRow ? "row" : "column")};
  align-items: center;
  color: white;
  padding: 16px;
  background: #272a2f;
  box-shadow: 0px 20px 40px #1d1f23, -20px -20px 40px #2b2f35;
  border-radius: 24px;
  color: ${(props) => (props.enabled ? "white" : "white")};
  width: 100%;
`;

const CenterControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;

const CrowdInnerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const WaveWrapper = styled.div<{ enabled: boolean }>`
  display: ${(props) => (props.enabled ? "block" : "none")};
`;

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const SmallButton = styled.button<{ enabled?: boolean }>`
  border: none;
  color: ${(props) => (props.enabled ? "#33FF00" : "#ef5da8")};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  height: 41px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) =>
    props.enabled ? "#24272c" : "linear-gradient(145deg, #202328, #272a2f)"};
  box-shadow: ${(props) =>
    props.enabled
      ? "inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339"
      : "20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35"};
  cursor: pointer;
`;

const CrowdHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HerriSoftLogo = styled.img`
  cursor: pointer;
`;

const ModalExterior = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    transition: all 400ms !important;
  }
`;

const ModalInterior = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #272a2f;
  padding: 16px;
  border-radius: 16px;
  color: white;
  outline: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const MenuButton = styled.button<{ enabled?: boolean }>`
  border: none;
  color: ${(props) => (props.enabled ? "#33FF00" : "#ef5da8")};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  height: 66px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) =>
    props.enabled ? "#24272c" : "linear-gradient(145deg, #202328, #272a2f)"};
  box-shadow: ${(props) =>
    props.enabled
      ? "inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339"
      : "20px 20px 40px #1d1f23, -20px -20px 40px #2b2f35"};
  cursor: pointer;
  &:active {
    transform: translateY(4px);
`;

const MenuSelect = styled.select`
  border: none;
  color: #ef5da8;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  height: 66px;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 8px;
  background: #24272c;
  box-shadow: inset 20px 20px 40px #191b1f, inset -20px -20px 40px #2f3339;
  outline: none;
  cursor: pointer;
`;

const MenuSelectContainer = styled.div`
  width: 100%;
  h4 {
    margin-bottom: 8px;
  }
`;

const CrowdSfxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
